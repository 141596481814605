import React from 'react';
import GradientText from '@/components/GradientText';
import { Box, Button, Container, Grid, styled, Typography } from '@mui/material';
import CheckIconMaterial from '@mui/icons-material/Check';
const CheckIcon = styled(CheckIconMaterial)`
  vertical-align: sub;
`;

export const FreeTrialCTA = () => {
  return (
    <Container maxWidth="lg">
      <Grid item sm={12}>
        <Typography sx={{ mb: 3 }} textAlign="center" variant="h2" gutterBottom>
          Start <GradientText>engaging</GradientText> with your audience
        </Typography>
        <Typography textAlign="center" color="primary" variant="body1" component="span">
          <Typography color="text.secondary" variant="subtitle1" gutterBottom>
            Free plan available <CheckIcon />
          </Typography>
          <Typography color="text.secondary" variant="subtitle1" gutterBottom>
            Cancel anytime <CheckIcon />
          </Typography>
          <Typography color="text.secondary" variant="subtitle1">
            No credit card required <CheckIcon />
          </Typography>
        </Typography>
        <Box sx={{ display: 'flex' }} mt={3}>
          <Button
            size="large"
            sx={{ m: 'auto' }}
            variant="contained"
            color="primary"
            href="https://app.pollpilot.io"
          >
            Get started for free
          </Button>
        </Box>
      </Grid>
    </Container>
  );
};
