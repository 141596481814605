import React, { useEffect, useRef } from 'react';

// <script async defer src="https://app.pollpilot.io/client.js" id="R9hQm2I38qxzJMG"></script>
export const Widget = ({ id }: { id: string }) => {
  const scriptEl = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const script = document.createElement('script');
    script.defer = true;
    script.id = id;
    script.src = 'https://app.pollpilot.io/client.js';
    scriptEl.current?.appendChild(script);
  }, []);

  return <div ref={scriptEl} />;
};
